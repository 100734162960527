<template>
  <div class="AboutCompany">
    <div class="AboutCompany__main">
      <h1 class="AboutCompany__title text-m28">
        B2B-маркетплейс <br> быстрых закупок
      </h1>
      <!-- Пока не надо -->
      <!-- <div class="AboutCompany__caption text-r12">
        АО «В2В Трэйд» — ИТ компания, обеспечивающая разработку платформы маркетплейса Максмарт
      </div> -->
    </div>
    <div class="AboutCompany__description text-r14">
      <div
        class="AboutCompany__text"
        :class="{ _expanded: isExpanded }"
      >
        <p>
          B2B-маркетплейс Максмарт – инновационный для российского рынка маркетплейс быстрых корпоративных закупок стандартизованной
          (long-tail) номенклатуры, который оптимизирует закупочный процесс для крупных промышленных, строительных компаний и бизнеса любого
          масштаба. Преимуществом Максмарт по сравнению с другими электронными торговыми площадками является обеспечение полного цикла
          сделки от получения потребности от покупателя до автоматического формирования корзины на основании мэппинга номенклатуры
          покупателя и маркетплейса, автоматического выставления документов на входящую поставку и поставки в базис получателя.
        </p>

        <p>
          B2B-площадка обеспечивает автоматизацию процесса корпоративных закупок и оцифровывает производителей, обеспечивает консолидацию
          заказов и оптовые поставки до покупателя.
        </p>

        <p>
          Маркетплейс быстрых оптовых закупок Максмарт обеспечивает поставщикам и производителям продукции выход в интернет-пространство и
          доступ к широкому рынку бизнес-клиентов, экономя время и ресурсы.
        </p>
      </div>
      <div class="AboutCompany__toggler">
        <MButton
          :type="MM.EButtonTypes.Text"
          :theme="MM.EButtonThemes.Primary"
          @click="isExpanded = !isExpanded"
        >
          {{ isExpanded ? 'Свернуть' : 'Показать далее' }}
        </MButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MM } from '~/types';

const isExpanded = ref<boolean>(false);
</script>

<style lang="scss">
@import 'styles/base/common/variables';

.AboutCompany {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 8px;
  background-color: $color-green-extra-dark;
  padding: 14px var(--container-offset-X);

  &__main {
    color: $color-white;
  }

  &__title {
    margin-bottom: 16px;
  }

  // Пока не надо
  // &__caption {
  //   color: $color-secondary;
  // }

  &__description {
    grid-column: auto / span 3;
  }

  &__text {
    color: $color-green-muted;
    p:not(:last-child) {
      margin-bottom: 28px;
    }
    p:last-child {
      margin-bottom: 0;
    }
  }

  &__toggler {
    margin-top: 8px;
    @include minw(768) {
      display: none;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -20px;
    height: 21px;
    left: 0;
    right: 0;
    background-color: $color-green-extra-dark;
    pointer-events: none;
  }

  @include maxw(1599) {
    &__caption {
      display: none;
    }
  }

  @include maxw(1279) {
    padding-bottom: 0;
    padding-top: 0;
    display: block;
  }

  @include maxw(767) {
    &__text {
      &:not(._expanded) {
        @include clamp(6);
      }
    }
  }
}
</style>
